import React, {Component} from 'react'
import {DataContext} from '../Context'
// import '../css/Details.css'
// import '../css/Cart.css'
import './Cart.css'
import {DeleteOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

export class Cart extends Component {
    static contextType = DataContext;

    componentDidMount() {
        this.context.getTotal();
    }

    checkout = () => {
        window.alert(`Thank you for the order. We will contact you shortly.`);
        this.back();
    }

    back = () => {
        this.props.history.goBack();
    }

    render() {
        const {cart, increase, decrease, removeProduct, total} = this.context;
        if (cart.length === 0) {
            return <>
                <h2 style={{textAlign: "center"}}>Cart is empty</h2>
                <div className="top">
                    <button className="button" onClick={ this.back}>BACK TO LISTING</button>
                </div>
            </>
        } else {
            return (
                <>
                    <div className="wrapper">
                        <h1 className="title">YOUR ORDER</h1>
                        <div className="top">
                            <button className="button" onClick={ this.back}>BACK TO LISTING</button>
                        </div>
                        <div className="bottom">
                            <div className="info">
                                {cart &&
                                cart.map(item => (
                                    <>
                                        <div className="product">
                                            <img className="image" alt={item.images[0].public_id}
                                                 src={item.images[0].url}/>
                                            <div className="details">
                                                <span>{item.title.slice(0, 140)}...</span>
                                            </div>
                                            <div className="price">${(item.price * item.count).toFixed(2)}</div>
                                            <div className="productAmount">{item.count}</div>
                                            <div className="action">
                                                <i className="count" onClick={() => decrease(item._id)}>
                                                    <MinusCircleOutlined style={{color: "blue", fontSize: '20px'}}/>
                                                </i>
                                                <i className="count" onClick={() => increase(item._id)}>
                                                    <PlusCircleOutlined style={{color: "green", fontSize: '20px'}}/>
                                                </i>
                                                <i className="count" onClick={() => removeProduct(item._id)}>
                                                    <DeleteOutlined style={{color: "red", fontSize: '20px'}}/>
                                                </i>
                                            </div>
                                        </div>
                                        <hr className="hr"/>
                                    </>
                                ))
                                }
                            </div>
                            <div className="summary">
                                <h1 className="summaryTitle">Order Summary</h1>
                                <div className="summaryItem">
                                    <span className="key">SubTotal:</span>
                                    <span className="value">$ {total.toFixed(2)}</span>
                                </div>
                                <div className="summaryItem">
                                    <span className="key">Delivery:</span>
                                    <span className="value">$ 5.90</span>
                                </div>
                                <div className="summaryItem">
                                    <span className="key">Delivery Discount:</span>
                                    <span className="value">$ -5.90</span>
                                </div>
                                <hr/>
                                <div className="summaryItemTotal">
                                    <span>Total:</span>
                                    <span>$ {total.toFixed(2)}</span>
                                </div>
                                <div className="summaryItem input">
                                    <input placeholder="Enter your email"/>
                                </div>
                                <button className="button" onClick={this.checkout}>CHECKOUT NOW</button>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}

export default Cart