import React, { Component } from "react";

export class Team extends Component {
  render() {
    return (
      <div id="teams" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Meet the Team</h2>
            <p>
              The best teams understand that everything they do will provide value to their customers or end users. This is why it is important for team members to know the greater goal and how their responsibilities are part of something greater.
            </p>
          </div>
          <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div  key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." style={{backgroundColor: "#00aeef"}} className="team-img" />
                      <div className="caption">
                        {/*<h4>{d.name}</h4>*/}
                        {/*<p>{d.job}</p>*/}
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
