import React, { Component } from "react";
import CategoryBlock from "./CategoryBlock";

export class OurProducts extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2">
            <h2><b>Our Products</b></h2>
          </div>


          <CategoryBlock title="Security" data={this.props.data}/>
          <CategoryBlock title="Camera & Photo" data={this.props.dataCam}/>
          <CategoryBlock title="Vehicle Electronics" data={this.props.dataVeh}/>
          <CategoryBlock title="Cell Phones & Accessories" data={this.props.dataCell}/>
          <CategoryBlock title="Computers" data={this.props.dataComp}/>
          <CategoryBlock title="Office Electronics" data={this.props.dataOffice}/>
          <CategoryBlock title="GPS & Navigation" data={this.props.dataGps}/>
          <CategoryBlock title="eBook Readers & Accessories" data={this.props.dataEbook}/>
          <CategoryBlock title="Televisions & Video Products" data={this.props.dataVideo}/>
          <CategoryBlock title="Smart Home" data={this.props.dataHome}/>
          <CategoryBlock title="Pet Supplies" data={this.props.dataPet}/>


        </div>
      </div>
    );
  }
}

export default OurProducts;
