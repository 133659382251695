import React, {Component} from "react";
import CartIcon from './shopping-cart-solid.svg'
import {Link} from "react-router-dom";
import {DataContext} from "../Context";
import '../css/Header.css';


export class HomeNav extends Component {
    static contextType = DataContext;

    render() {
        const {cart} = this.context;
        return (
            <nav className="navbar navbar-default navbar-fixed-top" style={{background: "#e3f2fd", fontWeight: "bold"}}>
                <div className="container">
                    <div className="navbar-header">
                        <button
                            type="button"
                            className="navbar-toggle collapsed"
                            data-toggle="collapse"
                            data-target="#bs-example-navbar-collapse-1"
                        >
                            {" "}
                            <span className="sr-only">Toggle navigation</span>{" "}
                            <span className="icon-bar"></span>{" "}
                            <span className="icon-bar"></span>{" "}
                            <span className="icon-bar"></span>{" "}
                        </button>
                        <Link to="/" className="navbar-brand page-scroll">
                            Shenzhen Tollar Security Equipment Co., Ltd.
                        </Link>{" "}
                    </div>

                    <div
                        className="collapse navbar-collapse"
                        id="bs-example-navbar-collapse-1"
                    >
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <a href="#about" className="page-scroll">
                                    Product
                                </a>
                            </li>
                            <li>
                                <a href="#desc" className="page-scroll">
                                    Description
                                </a>
                            </li>
                            <li>
                                <a href="#info" className="page-scroll">
                                    Information
                                </a>
                            </li>
                            <li>
                                <a href="#contact" className="page-scroll">
                                    Contact
                                </a>
                            </li>
                            <li className="nav-cart">
                                <span>{cart.length}</span>
                                <Link to="/cart">
                                    <img src={CartIcon} alt="" width="20"/>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default HomeNav;
