import React from "react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useRoutes} from "./routes";
import {useAuth} from "./hooks/auth.hook";
import {AuthContext} from "./context/AuthContext";
import {DataProvider} from "./components/Context";


const App = () => {
    const {token, login, logout, userId} = useAuth()
    const isAuthenticated = !!token
    const routes = useRoutes(isAuthenticated);

    return (
        <AuthContext.Provider value={{
            token, login, logout, userId, isAuthenticated
        }}>
            {/*<Header/>*/}
            <ToastContainer/>
            {isAuthenticated}
            {/*<div className="container">*/}
            <DataProvider>
                {routes}
            </DataProvider>
            {/*</div>*/}
        </AuthContext.Provider>
    );
}

export default App;
