import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Form, Input, Modal} from "antd";
import {Carousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PageListItems from "./PageListItems";
import PageAboutListItems from "./PageAboutListItems";
import {DataContext} from "../Context";
import {useHistory} from "react-router-dom";

const SinglePage = ({page}) => {
    const history = useHistory()
    const {title, images, brand, asin, marketplace, viewAsin, price} = page;
    const [visible, setVisible] = useState(false);


    const context = useContext(DataContext);

    useEffect(() => {
        console.log("asin:", asin);
        console.log("viewAsin:", asin);
    }, [])

    const modalHandler = (value) => {
        setVisible(value);
        window.alert(`Thank you for your interest in our product. We will contact you shortly.`);
    }

    const goToAmazon = () => {
        let domain = "https://www.amazon.com";
        if (marketplace && marketplace !== "") {
            domain = marketplace;
        }
        if (asin) {
            window.location.replace(domain + "/dp/" + asin);
        }
    }

    const addToCart = () => {
        context.addCart(page)
    }

    const goToCart = () => {
        context.addCart(page)
        history.push('/cart')
    }

    return (
        <>
            <div className="col-md-5">
                <Carousel showArrows={true} autoPlay infiniteLoop dynamicHeight={true} swipeable={true}>
                    {images && images.map((i) => <img src={i.url} key={i.public_id} alt="asd"/>)}
                </Carousel>
            </div>

            <div className="col-md-7">
                <h2 className="pl-1 pr-1 pt-1 pb-0">{title}</h2>
                <div className="pl-1 pr-1 pt-0 pb-1" style={{color: '#0075c8', fontSize: '16px'}}><b>Brand: {brand}</b>
                </div>


                {
                    price ?
                        <div style={{color: 'black', fontSize: '18px', marginTop: '20px', marginBottom: '20px'}}>$ {price}
                        </div>
                        : <div style={{color: 'red', fontSize: '18px', marginTop: '20px', marginBottom: '20px'}}>Currently unavailable</div>
                }

                <Card style={{width: 600, fontSize: "20px"}}>
                    <PageListItems page={page}/>
                </Card>


                {
                    price ?
                        <>
                            <div>
                                <Button onClick={addToCart}
                                        style={{
                                            marginTop: "20px",
                                            fontWeight: "bold",
                                            width: "65%",
                                            borderColor: "black",
                                            height: "40px"
                                        }}>
                                    Add to cart
                                </Button>
                            </div>
                            <div>
                                <Button onClick={goToCart}
                                        style={{
                                            marginTop: "10px",
                                            fontWeight: "bold",
                                            borderColor: "black",
                                            background: "black",
                                            color: "white",

                                            width: "65%",
                                            height: "40px"
                                        }}>
                                    Buy it now
                                </Button>
                            </div>
                        </>
                        : null
                }


                <div className="modal-window-button">
                    <Button type="danger" onClick={() => setVisible(true)} style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontWeight: "bold",
                        borderRadius: "20px",
                        width: "31%"
                    }}>
                        Order from 1000 pieces
                    </Button>
                    {(asin && viewAsin) ?
                        <Button onClick={() => goToAmazon()} style={
                            {
                                marginLeft: "20px",
                                marginTop: "20px",
                                marginBottom: "20px",
                                fontWeight: "bold",
                                background: "#FFA41C",
                                borderColor: "#FF8F00",
                                borderRadius: "20px",
                                width: "31%"
                            }
                        }>
                            Buy on Amazon
                        </Button>
                        : null
                    }
                    <Modal
                        title="Leave your details and we'll get back to you"
                        centered
                        visible={visible}
                        onOk={() => modalHandler(false)}
                        onCancel={() => setVisible(false)}
                        okText="Buy"
                        width={500}
                    >
                        <Form>
                            <Form.Item label="Name">
                                <Input placeholder="Enter your name"/>
                            </Form.Item>
                            <Form.Item label="Email">
                                <Input placeholder="Enter your email"/>
                            </Form.Item>
                            <Form.Item label="Phone">
                                <Input placeholder="Enter your phone number"/>
                            </Form.Item>
                        </Form>
                    </Modal>
                </div>

                <h4 className="p-1 mt-4"><b>About this item</b></h4>
                <PageAboutListItems page={page}/>
            </div>

        </>
    );
};

export default SinglePage;