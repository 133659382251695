import React, {Component} from "react";
import AdminNav from "../../components/nav/AdminNav";
import {createPage, getPage, getPages, removeImagesForPage, removePage} from "../../functions/page";
import AdminPageCard from "../../components/cards/AdminPageCard";
import {toast} from "react-toastify";
import SearchPanel from "../../components/search-panel/SearchPanel";
import {Button} from "antd";

export default class AllPages extends Component {

    state = {
        pages: [],
        loading: false,
        term: ''
    }

    componentDidMount() {
        this.loadAllPages();
    }

    loadAllPages = () => {
        this.setState({loading: true});
        getPages(300)
            .then((res) => {
                this.setState({pages: res.data});
                this.setState({loading: false});
            })
            .catch((err) => {
                this.setState({loading: false});
                console.log(err);
            });
    };

    handleCopyPage = (suffix) => {
        getPage(suffix)
            .then((res) => {
                console.log(res);
                delete res.data._id;
                res.data.suffix = suffix + "-2";
                res.data.slug = res.data.slug + "2";
                createPage(res.data)
                    .then((res) => {
                        window.alert(`"${res.data.title}" was copied`);
                        window.location.reload();
                    })
                    .catch((err) => {
                        console.log(err);
                        // if (err.response.status === 400) toast.error(err.response.data);
                        toast.error(err.response.data.err);
                    });
            })
            .catch((err) => {
                console.log(err);
                // if (err.response.status === 400) toast.error(err.response.data);
                toast.error(err.response.data.err);
            });
    };

    handleRemove = (suffix, images) => {
        let answer = window.confirm("Are you sure to DELETE?");
        if (answer) {
            removeImagesForPage(images)
                .then(() => {
                    removePage(suffix)
                        .then(res => {
                            this.loadAllPages();
                            toast.success(`${res.data.title.substring(0, 30)} is deleted!`)
                        })
                        .catch(err => {
                            if (err.response.status === 400) toast.error(err.response.data);
                            console.log(err)
                        })
                })
                .catch(e => {
                    toast.error(e.response.data);
                    console.log(e)
                })
        }
    }

    handleRemoveChecked = async () => {
        const {pages} = this.state;
        let isDeleted = false;
        if (pages) {
            let answer = window.confirm("Are you sure to DELETE all selected pages?");
            this.setState({loading: true});
            for (const page of pages) {
                if (page.checked) {
                    isDeleted = true;
                    if (answer) {
                        try {
                            await removeImagesForPage(page.images);
                            await removePage(page.suffix);
                        } catch (e) {
                            this.setState({loading: false});
                            toast.error(e.response.data);
                            console.log(e);
                        }
                    }
                }
            }
            this.setState({loading: false});
            if (isDeleted) {
                await this.loadAllPages();
                toast.success(`Selected pages was deleted!`)
            }
        }
    }

    onSearchChange = (term) => {
        this.setState({term});
    }

    search(items, term) {
        if (term.length === 0) {
            return items;
        }

        return items.filter((item) => {
            return item.title.toLowerCase().indexOf(term.toLowerCase()) > -1
                || term.toLowerCase().includes(item.suffix.toLowerCase())
                || item.suffix.toLowerCase().indexOf(term.toLowerCase()) > -1;
        })
    }

    render() {
        const {pages, loading, term} = this.state;
        const visiblePages = this.search(pages, term);

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-2">
                        <AdminNav/>
                    </div>

                    <div className="col-md-10">
                        {loading ? (
                            <h4 className="text-danger">Loading...</h4>
                        ) : (
                            <div className="row">
                                <div className="col-md-2">
                                    <h2>All Pages</h2>
                                </div>
                                <div className="col-md-8">
                                    <SearchPanel onSearchChange={this.onSearchChange}/>
                                </div>
                                <div className="col-md-2">
                                    <Button onClick={this.handleRemoveChecked}
                                            style={{
                                                marginTop: "2px",
                                                fontWeight: "bold",
                                                borderColor: "red",
                                                color: "red",
                                            }}>
                                        Delete selected
                                    </Button>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            {visiblePages.map((page) => (
                                <div key={page._id} className="col-md-4 pb-3">
                                    <AdminPageCard page={page}
                                                   handleCopyPage={this.handleCopyPage}
                                                   handleRemove={this.handleRemove}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}