import React, {Fragment} from 'react';

const CategoryBlock = ({title, data}) => {

    return (
        <Fragment>
            <div className="col-md-8 col-md-offset-2 section-title">
                <h2>{title}</h2>
            </div>
            <div id="row">
                {data
                    ? data.map((d, i) => (
                        <div  key={`${d.name}-${i}`} className="col-md-3 col-sm-6">
                            <div className="thumbnail">
                                {" "}
                                <img src={d.img} alt="..." style={{backgroundColor: "#00aeef"}} className="team-img" />
                                <div className="caption">
                                    <h4>{d.name}</h4>
                                    <p>{d.job}</p>
                                </div>
                            </div>
                        </div>
                    ))
                    : "loading"}
            </div>
        </Fragment>
    )
}

export default CategoryBlock;