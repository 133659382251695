import React, {useEffect, useState} from "react";
import JsonData from '../data/data.json';
import Navigation from "../components/landing/navigation";
import Header from "../components/landing/header";
import OurProducts from "../components/landing/OurProducts";
import Features from "../components/landing/features";
import Testimonials from "../components/landing/testimonials";
import Team from "../components/landing/Team";
import Contact from "../components/landing/contact";

const LandingPage = () => {
    const [state, setState] = useState({
        landingPageData: {},
    })

    useEffect(() => {
        getLandingPageData()
    }, [])

    const getLandingPageData = () => {
        setState({landingPageData: JsonData})
    }

    return (
        <div>
            <Navigation />
            <Header data={state.landingPageData.Header}/>

            <OurProducts data={state.landingPageData.Products}
                         dataCam={state.landingPageData.ProductsCameras}
                         dataVeh={state.landingPageData.VehicleElectronics}
                         dataCell={state.landingPageData.CellPhones}
                         dataComp={state.landingPageData.Computers}
                         dataOffice={state.landingPageData.Office}
                         dataGps={state.landingPageData.Gps}
                         dataEbook={state.landingPageData.Ebook}
                         dataVideo={state.landingPageData.Video}
                         dataHome={state.landingPageData.Home}
                         dataPet={state.landingPageData.PetSupplies}
            />
            <Features data={state.landingPageData.Features}/>

            <Team data={state.landingPageData.Team} />
            <Testimonials data={state.landingPageData.Testimonials} />
            <Contact data={state.landingPageData.Contact} />
        </div>
    )
}

export default LandingPage;