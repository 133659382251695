import React, {useContext} from "react";
import {Link, useHistory} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

const AdminNav = () => {
    const history = useHistory()
    const auth = useContext(AuthContext)

    const logoutHandler = event => {
        event.preventDefault()
        auth.logout()
        history.push('/')
    }

    return (
        <nav>
            <ul className="nav flex-column">
                <li className="nav-item">
                    <Link to="/admin/page" className="nav-link">
                        New Page
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/admin/pages" className="nav-link">
                        All Pages
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/" className="nav-link" onClick={logoutHandler}>
                        Logout
                    </Link>
                </li>
            </ul>
        </nav>
    )
};

export default AdminNav;
