import React, {Component} from "react";
import "./HomeFooter.css";

export class HomeFooter extends Component {
    render() {
        return (
            <>
                <div id="contact" className="footer">
                    <div className="container">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="section-title">
                                    <h2>Get In Touch</h2>
                                    <p>
                                        Please fill out the form below to send us an email and we
                                        will get back to you as soon as possible.
                                    </p>
                                </div>
                                <form name="sentMessage" id="contactForm" noValidate>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    required="required"
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    required="required"
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            className="form-control"
                                            rows="4"
                                            placeholder="Message"
                                            required
                                        ></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-custom">
                                        Send Message
                                    </button>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-3 col-md-offset-1 contact-info">
                            <div className="contact-item">
                                <h3>Contact Info</h3>
                                <span className="address">Address</span>
                                <p>2F.Section A.No.3 Eastern.ShangXue Industrial Park.LongGang District.ShenZhen,
                                    China</p>
                            </div>
                            <div className="contact-item">
                                <span className="address">Phone</span>
                                <p>+1 123 456 1234</p>
                            </div>
                            <div className="contact-item">
                                <span className="address">Email</span>
                                <p>manufacturer.news@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    <div className="container text-center">
                        <p>
                            &copy; 2021 Issaaf Kattan React Land Page. Design by{" "}
                            <a href="https://www.sirgawain.net/" rel="nofollow">
                                SirgawainCompany
                            </a>
                        </p>
                    </div>
                </div>
            </>
        );
    }
}

export default HomeFooter;
