import React, {useContext, useState} from 'react';
import {login} from "../functions/auth";
import {toast} from "react-toastify";
import {AuthContext} from "../context/AuthContext";

const AuthPage = () => {
    const auth = useContext(AuthContext)
    const [form, setForm] = useState({
        email: '', password: ''
    })

    const changeHandler = event => {
        setForm({...form, [event.target.name]: event.target.value})
    }

    const loginHandler = async () => {
        login(form)
            .then(res => {
                auth.login(res.data.token, res.data.userId)
            })
            .catch(err => {
                toast.error(err.response.data.err);
                console.log("fetch logged user failed", err)
            })
    }

    return (
        <div className="container">
            <h1>Admin Panel</h1>
            <div className="form-group row">
                <label htmlFor="email" className="col-sm-2 col-form-label">Email address</label>
                <div className="col-sm-3">
                    <input type="email"
                           className="form-control"
                           id="email"
                           name="email"
                           aria-describedby="emailHelp"
                           placeholder="Enter email"
                           onChange={changeHandler}/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="password" className="col-sm-2 col-form-label">Password</label>
                <div className="col-sm-3">
                    <input type="password"
                           className="form-control"
                           id="password"
                           name="password"
                           placeholder="Password"
                           onChange={changeHandler}/>
                </div>
            </div>

            <button className="btn btn-primary" onClick={loginHandler}>Submit</button>
        </div>
    )
}

export default AuthPage;