import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Home from "./pages/Home";
import PageCreate from "./pages/page/PageCreate";
import AllPages from "./pages/page/AllPages";
import PageUpdate from "./pages/page/PageUpdate";
import AuthPage from "./pages/AuthPage";
import LandingPage from "./pages/LandingPage";
import Cart from "./components/cards/Cart";

export const useRoutes = isAuthenticated => {
    if (isAuthenticated) {
        return (
            <div className="container">
                <Switch>
                    {/*<Route exact path="/" component={LandingPage}/>*/}
                    <Route exact path="/admin/page" component={PageCreate}/>
                    <Route exact path="/admin/pages" component={AllPages}/>
                    <Route exact path="/admin/page/:suffix" component={PageUpdate}/>
                    <Route exact path="/:prefix/:suffix" component={Home}/>
                    <Route exact path="/cart" component={Cart}/>
                    <Redirect to="/admin/pages"/>
                </Switch>
            </div>
        )
    }

    return (
        <Switch>
            <Route exact path="/" component={LandingPage}/>
            {/*<Route exact path="/" component={NotFoundPage}/>*/}
            <div className="container">
                <Route exact path="/:prefix/:suffix" component={Home}/>
                <Route exact path="/cart" component={Cart}/>
                <Route path="/admin" exact>
                    <AuthPage/>
                </Route>
                {/*<Redirect to="/admin"/>*/}
            </div>
        </Switch>
    )
}