import React, {Fragment} from 'react';
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
]

const modules = {
    toolbar: [
        [{'header': '1'}, {'header': '2'}, {'font': []}],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'},
            {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
    }
}

const PageCreteForm = ({
                           handleSubmit,
                           handleChange,
                           handleInputChange,
                           handleAddFields,
                           handleRemoveFields,
                           handleInputInfoChange,
                           handleAddInfoFields,
                           handleRemoveInfoFields,
                           handleDescriptionChangeQuill,
                           handleAboutChangeQuill,
                           values
                       }) => {
    const {
        suffix,
        title,
        brand,
        inputFields,
        about,
        description,
        inputInfoFields,
    } = values;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label><b>Url suffix</b></label>
                <input
                    type="text"
                    name="suffix"
                    className="form-control"
                    value={suffix}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label><b>Title</b></label>
                <input
                    type="text"
                    name="title"
                    className="form-control"
                    value={title}
                    onChange={handleChange}
                />
            </div>
            <div className="form-group">
                <label><b>Brand</b></label>
                <input
                    type="text"
                    name="brand"
                    className="form-control"
                    value={brand}
                    onChange={handleChange}
                />
            </div>


            <div className="form-group">
                <div className="row">
                    <label><b>Enter Details Fields:</b></label>
                </div>
                <div className="row">
                    {inputFields.map((inputField, index) => (
                        <Fragment key={`${inputField}~${index}`}>
                            <div className="form-group col-sm-6">
                                <label htmlFor="firstName">Field name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={inputField.name}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label htmlFor="lastName">Field value</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="value"
                                    name="value"
                                    value={inputField.value}
                                    onChange={event => handleInputChange(index, event)}
                                />
                            </div>
                            <div className="form-group col-sm-2">
                                <button
                                    className="btn btn-link"
                                    type="button"
                                    onClick={() => handleRemoveFields(index)}
                                >
                                    -
                                </button>
                                <button
                                    className="btn btn-link"
                                    type="button"
                                    onClick={() => handleAddFields()}
                                >
                                    +
                                </button>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>

            <div className="form-group">
                <div className="row">
                    <label><b>Enter Product Information Fields:</b></label>
                </div>
                <div className="row">
                    {inputInfoFields.map((inputField, index) => (
                        <Fragment key={`${inputField}~${index}`}>
                            <div className="form-group col-sm-6">
                                <label htmlFor="firstName">Field name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    value={inputField.name}
                                    onChange={event => handleInputInfoChange(index, event)}
                                />
                            </div>
                            <div className="form-group col-sm-4">
                                <label htmlFor="lastName">Field value</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="value"
                                    name="value"
                                    value={inputField.value}
                                    onChange={event => handleInputInfoChange(index, event)}
                                />
                            </div>
                            <div className="form-group col-sm-2">
                                <button
                                    className="btn btn-link"
                                    type="button"
                                    onClick={() => handleRemoveInfoFields(index)}
                                >
                                    -
                                </button>
                                <button
                                    className="btn btn-link"
                                    type="button"
                                    onClick={() => handleAddInfoFields()}
                                >
                                    +
                                </button>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>


            <div className="form-group">
                <div className="row">
                    <label><b>About this item</b></label>
                </div>
                <ReactQuill
                    onChange={handleAboutChangeQuill}
                    theme='snow'
                    modules={modules}
                    formats={formats}
                />
            </div>
            <div className="form-group">
                <div className="row">
                    <label><b>Description</b></label>
                </div>
                <ReactQuill
                    onChange={handleDescriptionChangeQuill}
                    theme='snow'
                    modules={modules}
                    formats={formats}
                />
            </div>
            <button className="btn btn-danger">Save</button>
        </form>

    )
}

export default PageCreteForm;