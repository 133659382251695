import React from 'react';


const PageAboutListItems = ({page}) => {
    const {about} = page;
    return (
        <div className="row p-3" style={{fontSize: "16px"}}>
            <div dangerouslySetInnerHTML={{__html: about}}/>
        </div>
    )
}

export default PageAboutListItems;