import React, {useState} from "react";
import AdminNav from "../../components/nav/AdminNav";
import {createPage} from "../../functions/page";
import {toast} from "react-toastify";
import PageCreteForm from "../../components/forms/PageCreateForm";
import FileUpload from "../../components/forms/FileUpload";
import {LoadingOutlined} from '@ant-design/icons';

const initState = {
    suffix: "",
    domain: "",
    title: "",
    brand: "",
    inputFields: [{ name: '', value: '' }],
    about: "",
    description: "",
    inputInfoFields: [{ name: '', value: '' }],
    images: []
};

const PageCreate = () => {
    const [values, setValues] = useState(initState);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("PageCreate hostname:", window.location.hostname);
        values.domain = window.location.hostname;
        createPage(values)
            .then((res) => {
                console.log(res);
                window.alert(`"${res.data.title}" is created`);
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
                // if (err.response.status === 400) toast.error(err.response.data);
                toast.error(err.response.data.err);
            });
    };

    const handleChange = (e) => {
        if (e.target.name === "suffix") {
            e.target.value =e.target.value.replace(/\s/g, "-")
        }
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleInputChange = (index, e) => {
        const currentValues = {...values};
        if (e.target.name === "name") {
            currentValues.inputFields[index].name = e.target.value;
        } else {
            currentValues.inputFields[index].value = e.target.value;
        }
        setValues(currentValues);
    };

    const handleAddFields = () => {
        const currentValues = {...values};
        currentValues.inputFields.push({ name: '', value: '' });
        setValues(currentValues);
    };

    const handleRemoveFields = index => {
        const currentValues = {...values};
        currentValues.inputFields.splice(index, 1);
        setValues(currentValues);
    };

    const handleInputInfoChange = (index, e) => {
        const currentValues = {...values};
        if (e.target.name === "name") {
            currentValues.inputInfoFields[index].name = e.target.value;
        } else {
            currentValues.inputInfoFields[index].value = e.target.value;
        }
        setValues(currentValues);
    };

    const handleAddInfoFields = () => {
        const currentValues = {...values};
        currentValues.inputInfoFields.push({ name: '', value: '' });
        setValues(currentValues);
    };

    const handleRemoveInfoFields = index => {
        const currentValues = {...values};
        currentValues.inputInfoFields.splice(index, 1);
        setValues(currentValues);
    };

    const handleDescriptionChangeQuill = (e) => {
        setValues((prev) => {
            return {
                ...prev,
                description: e
            }
        })
    };
    const handleAboutChangeQuill = (e) => {
        setValues((prev) => {
            return {
                ...prev,
                about: e
            }
        })
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminNav/>
                </div>
                <div className="col-md-10">
                    {loading ? (
                        <LoadingOutlined className="text-danger h1"/>
                    ) : (
                        <h4>New Page Create</h4>
                    )}
                    <hr/>

                    {/*{JSON.stringify(values.images)}*/}

                    <div className="p-3">
                        <FileUpload values={values} setValues={setValues} setLoading={setLoading}/>
                    </div>

                    <PageCreteForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        handleInputChange={handleInputChange}
                        handleAddFields={handleAddFields}
                        handleRemoveFields={handleRemoveFields}
                        handleInputInfoChange={handleInputInfoChange}
                        handleAddInfoFields={handleAddInfoFields}
                        handleRemoveInfoFields={handleRemoveInfoFields}
                        handleDescriptionChangeQuill={handleDescriptionChangeQuill}
                        handleAboutChangeQuill={handleAboutChangeQuill}
                        values={values}/>
                </div>
            </div>
        </div>
    )
}

export default PageCreate;