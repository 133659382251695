import React, {useEffect, useState} from "react";
import {Card, Checkbox} from "antd";
import {CopyOutlined, DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom";

const {Meta} = Card;

const AdminPageCard = ({page, handleCopyPage, handleRemove}) => {
    // destructure
    const {title, images, suffix, brand, domain} = page;
    const [link, setLink] = useState(null);

    useEffect(() => {
        getDomain()
    }, [])

    const getDomain = () => {
        console.log("DOMAIN:" + domain);
        console.log("HOSTNAME111:" + window.location.hostname);
        // if (domain && domain !== "" && domain !== window.location.hostname) {
        if (domain && domain !== "") {
            if (window.location.hostname === "sirgawain.pro") {
                setLink("https://" + domain + "/catalog/" + suffix);
            } else {
                setLink("https://" + domain + "/" + brand.toLowerCase() + "/" + suffix);
            }
        }
        console.log("LINK:" + link);
    }

    const redirect = () => {
        window.location.replace(link);
    };

    const onChangeCheckbox = (e) => {
        console.log(`checked = ${e.target.checked}`);
        page.checked = e.target.checked;
    }

    return (
        <Card bodyStyle={{backgroundColor: suffix.endsWith("-2") ? '#87CEEB' : ''}}
              cover={
                  <img
                      src={images && images.length ? images[0].url : ""}
                      style={{height: "250px", objectFit: "cover"}}
                      className="m-2"
                  />
              }
              actions={[
                  <Link to={`/admin/page/${suffix}`}>
                      <EditOutlined className="text-warning"/>
                  </Link>,
                  <CopyOutlined onClick={() => handleCopyPage(suffix)}/>,
                  <DeleteOutlined onClick={() => handleRemove(suffix, images)}
                                  className="text-danger"/>,
                  <Checkbox onChange={onChangeCheckbox}/>
              ]}
        >
            {link ? (
                <a onClick={redirect}>
                    <Meta title={title}/>
                </a>
            ) : (
                <Link to={`/${brand.toLowerCase()}/${suffix}`}>
                    <Meta title={title}/>
                </Link>
            )}
        </Card>
    );
}

export default AdminPageCard;
