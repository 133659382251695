import axios from "axios";

export const getPages = async (count) => {
    return await axios.get(`/api/pages/${count}`);
}

export const getPage = async (suffix) => {
    return await axios.get(`/api/page/${suffix}`);
}

export const removePage = async (suffix) => {
    return await axios.delete(`/api/page/${suffix}`);
}

export const updatePage = async (suffix, page) => {
    return await axios.put(`/api/page/${suffix}`, page);
}

export const createPage = async (page) =>
    await axios.post(`/api/page`, page);

export const removeImagesForPage = async (images) => {
    return await axios.post(`/api/removeallimages`, {images: images});
}