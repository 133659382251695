import React, {useEffect, useState} from "react";
import AdminNav from "../../components/nav/AdminNav";
import {getPage, updatePage} from "../../functions/page";
import {toast} from "react-toastify";
import FileUpload from "../../components/forms/FileUpload";
import {LoadingOutlined} from '@ant-design/icons';
import PageUpdateForm from "../../components/forms/PageUpdateForm";

const initState = {
    suffix: "",
    title: "",
    price: "",
    brand: "",
    inputFields: [{name: '', value: ''}],
    about: "",
    description: "",
    inputInfoFields: [{name: '', value: ''}],
    images: []
};

const PageUpdate = (props) => {
    const [values, setValues] = useState(initState);
    const [loading, setLoading] = useState(false);
    const {suffix} = props.match.params;

    useEffect(() => {
        loadPage()
    }, [])

    const loadPage = () => {
        getPage(suffix)
            .then(p => {
                setValues({...values, ...p.data})
            })
            .catch()
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        updatePage(suffix, values)
            .then(res => {
                setLoading(false);
                toast.success(`${res.data.title} is updated`);
                props.history.push("/admin/pages");
            })
            .catch(err => {
                console.log(err);
                setLoading(false);
                toast.error(err.response.data.err);
            })
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    };

    const handleDescriptionChangeQuill = (e) => {
        setValues((prev) => {
            return {
                ...prev,
                description: e
            }
        })
    };
    const handleAboutChangeQuill = (e) => {
        setValues((prev) => {
            return {
                ...prev,
                about: e
            }
        })
    };

    const handleInputChange = (index, e) => {
        const currentValues = {...values};
        if (e.target.name === "name") {
            currentValues.inputFields[index].name = e.target.value;
        } else {
            currentValues.inputFields[index].value = e.target.value;
        }
        setValues(currentValues);
    };

    const handleAddFields = () => {
        const currentValues = {...values};
        currentValues.inputFields.push({name: '', value: ''});
        setValues(currentValues);
    };

    const handleRemoveFields = index => {
        const currentValues = {...values};
        currentValues.inputFields.splice(index, 1);
        setValues(currentValues);
    };

    const handleInputInfoChange = (index, e) => {
        const currentValues = {...values};
        if (e.target.name === "name") {
            currentValues.inputInfoFields[index].name = e.target.value;
        } else {
            currentValues.inputInfoFields[index].value = e.target.value;
        }
        setValues(currentValues);
    };

    const handleAddInfoFields = () => {
        const currentValues = {...values};
        currentValues.inputInfoFields.push({name: '', value: ''});
        setValues(currentValues);
    };

    const handleRemoveInfoFields = index => {
        const currentValues = {...values};
        currentValues.inputInfoFields.splice(index, 1);
        setValues(currentValues);
    };


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-2">
                    <AdminNav/>
                </div>
                <div className="col-md-10">
                    {loading ? (
                        <LoadingOutlined className="text-danger h1"/>
                    ) : (
                        <h4>Page Update</h4>
                    )}

                    <div className="p-3">
                        <FileUpload values={values} setValues={setValues} setLoading={setLoading}/>
                    </div>

                    <PageUpdateForm
                        handleSubmit={handleSubmit}
                        handleChange={handleChange}
                        handleInputChange={handleInputChange}
                        handleAddFields={handleAddFields}
                        handleRemoveFields={handleRemoveFields}
                        handleInputInfoChange={handleInputInfoChange}
                        handleAddInfoFields={handleAddInfoFields}
                        handleRemoveInfoFields={handleRemoveInfoFields}
                        handleDescriptionChangeQuill={handleDescriptionChangeQuill}
                        handleAboutChangeQuill={handleAboutChangeQuill}
                        values={values}
                    />
                    <hr/>
                </div>
            </div>
        </div>
    )
}

export default PageUpdate;