import React from 'react';
import {Table} from "antd";


const columns = [
    {
        title: '',
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        className: 'font-weight-bold',
        render: text => <div style={{fontSize: "14px"}}>{text}</div>,
    },
    {
        title: '',
        dataIndex: 'value',
        key: 'value',
        width: '50%',
        render: text => <div style={{fontSize: "14px"}}>{text}</div>,
    }
];


const PageListItems = ({page}) => {
    const {inputFields, viewAsin} = page;

    return (
        <Table dataSource={viewAsin ? inputFields : inputFields?.filter(item => item.name !== 'ASIN')} rowKey={record => record.name} columns={columns} pagination={false}
               bordered={false} showHeader={false}/>
    )
}

export default PageListItems;