import React, {useEffect, useState} from "react";
import {getPage} from "../functions/page";
import SinglePage from "../components/cards/SinglePage";
import {Card} from "antd";
import PageInfoListItems from "../components/cards/PageInfoListItems";
import "./Home.css";
import {HomeNav} from "../components/nav/HomeNav";
import {HomeFooter} from "../components/footer/HomeFooter";

const Home = (props) => {
    const [page, setPage] = useState({});
    const {suffix} = props.match.params;
    const {prefix} = props.match.params;

    useEffect(() => {
        console.log("HOSTNAME:", window.location.hostname);
        console.log("ORIGIN:", window.location.origin);
        loadPage()
    }, [])

    const loadPage = () => {
        getPage(suffix)
            .then(p => {
                if (p.data.brand.toUpperCase() === prefix.toUpperCase() || prefix.toUpperCase() === "CATALOG") {
                    console.log("DOMAIN:", p.data.domain);
                    if ((!p.data.domain || p.data.domain === "")) {
                        setPage(p.data);
                    } else if (window.location.hostname === p.data.domain) {
                        setPage(p.data);
                    } else {
                        setPage(null);
                    }
                } else {
                    setPage(null);
                }
            })
            .catch(err => {
                console.log("fetch single page failed", err);
                setPage(null);
            });
    }

    if (!page) {
        return <h1>Page Not Found</h1>
    }

    return (
        <>
            <HomeNav/>
            <div className="home">
                <div id="about" className="row pt-4">
                    <SinglePage page={page}/>
                </div>

                <div id="desc" className="row p-3">
                    <h3><b>Product description</b></h3>
                </div>
                <div className="row p-3" style={{fontSize: "16px"}}>
                    <div dangerouslySetInnerHTML={{__html: page.description}}/>
                </div>
                <div id="info" className="row p-3">
                    <h3><b>Product information</b></h3>
                </div>
                <div className="row">
                    <Card style={{width: 800, fontSize: "20px"}}>
                        <PageInfoListItems page={page}/>
                    </Card>
                </div>
                <div className="row">
                    <HomeFooter/>
                </div>
            </div>
        </>
    )
}

export default Home;